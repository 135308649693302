import { request } from "./Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";
const {API_URL } = urlConstants;

export default class QueryService {
  static registerChild(body) {
    return request(
      "POST",
      `${API_URL}${routes.registerChild.registerChild}`,
      null,
      body, 
      null
    );
  }
  
  static getDetails(body) {
    return request(
      "POST",
      `${API_URL}${routes.STUDENT.STUDENT_HISTORY}`,
      null,
      body,
      null
    );
  }
  static addStudent(body) {
    return request(
      "POST",
      `${API_URL}${routes.studentgoal.myfavStudent}`,
      null,
      body,
      null
    );
  }

  static downloadTrans(body) {
    return request(
      "POST",
      `${API_URL}${routes.studentgoal.downloadTrans}`,
      null,
      body,
      null
    );
  }

  static deleteTrans(body) {
    return request(
      "DELETE",
      `${API_URL}${routes.studentgoal.deleteTrans}`,
      null,
      body,
      null
    );
  }

  static getGender() {
    return request(
      "GET",
      `${API_URL}${routes.getGender.GET_GENDER}`,
      null,
      null,
      null
    );
  }

  //siembra
  static getLatestConfig() {
    return request(
      "GET",
      `${API_URL}${routes.configRecruiter.get_ethnicities}`,
      null,
      null,
      null
    );
  }
  //it
  static getEthnicity() {
    return request(
      "GET",
      `${API_URL}${routes.getEthnicity.GET_ETHNICITIES_IT}`,
      null,
      null,
      null
    );
  }

  //CCC
  static getCCC() {
    return request(
      "GET",
      `${API_URL}${routes.getCCC.GET_CCC}`,
      null,
      null,
      null
    );
  }

  //International
  static getInternational() {
    return request(
      "GET",
      `${API_URL}${routes.getInternational.GET_INTERNATIONAL}`,
      null,
      null,
      null
    );
  }

  //Siembra
  static getAcademics(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAI.GET_AAAI}`,
      null,
      body,
      null
    );
  }
  static getActivities(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAI.GET_AAAI}`,
      null,
      body,
      null
    );
  }
  static getAthletics(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAI.GET_AAAI}`,
      null,
      body,
      null
    );
  }
  static getInterest(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAI.GET_AAAI}`,
      null,
      body,
      null
    );
  }

  //siembra
  static getStemData(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAI.GET_AAAI}`,
      null,
      body,
      null
    );
  }

  //CCC
  static getCollageDataCCC(body) {
    return request(
      "GET",
      `${API_URL}${routes.getAAAIS.GET_AAAIS}`,
      null,
      null,
      null
    );
  }

  //CCC
  static getAcademicsCCC(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAIS.GET_AAAIS}`,
      null,
      body,
      null
    );
  }
  static getActivitiesCCC(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAIS.GET_AAAIS}`,
      null,
      body,
      null
    );
  }
  static getAthleticsCCC(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAIS.GET_AAAIS}`,
      null,
      body,
      null
    );
  }
  static getInterestCCC(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAIS.GET_AAAIS}`,
      null,
      body,
      null
    );
  }
  static getDasCCC(body) {
    return request(
      "POST",
      `${API_URL}${routes.getAAAIS.GET_AAAIS}`,
      null,
      body,
      null
    );
  }

  static getConfigData() {
    return request(
      "GET",
      `${API_URL}${routes.config.getConfig}`,
      null,
      null,
      null
    );
  }
  static getQueryResult(body) {
    return request(
      "POST",
      `${API_URL}${routes.getQueryResult.getQueryResult}`,
      null,
      body,
      null
    );
  }
  static getAdvanceParameters() {
    return request(
      "GET",
      `${API_URL}${routes.getAdvanceParameters.getAdvanceParameters}`,
      null,
      null,
      null
    );
  }
  static getResultDisplay(body) {
    return request(
      "POST",
      `${API_URL}${routes.getResultDisplay.getResultDisplay}`,
      null,
      body,
      null
    );
  }

  //custom-query
  static getResultPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.getResultPage.getResultPage}`,
      null,
      body,
      null
    );
  }

  //fav premium student
  static getFavoritePage() {
    return request(
      "GET",
      `${API_URL}${routes.getFavoritePage.getFavoritePage}`,
      null,
      null,
      null
    );
  }

  //college-query
  static getCollegeResultPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.getCollegeResultPage.getCollegeResultPage}`,
      null,
      body,
      null
    );
  }

  //international-query
  static getInternationalResultPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.getInternationalResultPage.getInternationalResultPage}`,
      null,
      body,
      null
    );
  }

  static getSavedQueryResultPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.getCampaignResultTable.getCampaignResultTable}`,
      null,
      body,
      null
    );
  }

  static getSchoolQueryPage(id,type,page) {
    return request(
      "GET",
      `${API_URL}${routes.getSchoolPage.getSchoolQuery}?college_id=${id}&query_type=${type}&page=${page}`,
      null,
      null,
      null
    );
  }
  static deleteQuery(body) {
    return request(
      "POST",
      `${API_URL}${routes.deleteQuery.deleteQuery}`,
      null,
      body,
      null
    );
  }
  static duplicateQueryForSaved(body) {
    return request(
      "POST",
      `${API_URL}${routes.duplicateQueryForSaved.duplicateQueryForSaved}`,
      null,
      body,
      null
    );
  }
  static saveQuery(body) {
    return request(
      "POST",
      `${API_URL}${routes.save_via_query.save_via_query}`,
      null,
      body,
      null
    );
  }
  static saveResultFromSaved(body) {
    return request(
      "POST",
      `${API_URL}${routes.save_via_result.save_via_result}`,
      null,
      body,
      null
    );
  }
  static saveQueryForCollege(body) {
    return request(
      "POST",
      `${API_URL}${routes.saveQueryForCollege.saveQueryForCollege}`,
      null,
      body,
      null
    );
  }
  static getHighSchoolName(queryValue) {
    return request(
      "GET",
      `${API_URL}${routes.highSchoolName.highSchoolName}?school=${queryValue}`,
      null,
      null,
      null
    );
  }
  static getViewquery(body) {
    return request(
      "PUT",
      `${API_URL}${routes.viewQuery.viewQuery}`,
      null,
      body,
      null,
    );
  }
  static getDuplicateQuery(body) {
    return request(
      "POST",
      `${API_URL}${routes.duplicateQuery.duplicateQuery}`,
      null,
      body,
      null
    );
  }
  static getDuplicateQueryForSavedResult(body) {
    return request(
      "POST",
      `${API_URL}${routes.duplicateQueryForSavedResult.duplicateQueryForSavedResult}`,
      null,
      body,
      null
    );
  }
  static deleteQueryForSchool(id) {
    return request(
      "DELETE",
      `${API_URL}${routes.deleteForSchoolQuery.deleteForSchoolQuery}?id=${id}`,
      null,
      null,
      null
    );
  }
  //siembra
  static exportResult(body) {
    return request(
      "POST",
      `${API_URL}${routes.export_result.export_result}`,
      null,
      body,
      null
    );
  }
  //ccc
  static exportResultCCC(body) {
    return request(
      "POST",
      `${API_URL}${routes.export_result_ccc.export_result_ccc}`,
      null,
      body,
      null
    );
  }

  //international
  static exportResultInternational(body) {
    return request(
      "POST",
      `${API_URL}${routes.export_result_international.export_result_international}`,
      null,
      body,
      null
    );
  }
}
