import React, { Fragment, useState, useEffect } from "react";
import QueryService from "../../services/QueryService";
import { useHistory, useLocation } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "@mui/styles";
import SaveModal from "../saveModal/SaveModal";
import CustomLoader from "../customLoader/CustomLoader";
import ErrorModal from "../errorDisplayModal/ErrorDisplayModal";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      fontSize: "14px",
    },
  },
});

const ShowAlert = ({ number, onCancel, onConfirm }) => (
  <SweetAlert
    warning
    showCancel
    showConfirm
    showCloseButton
    title={
      <p
        style={{ fontSize: "20px" }}
      >{`You have selected ${number} students!`}</p>
    }
    onConfirm={onConfirm}
    onCancel={onCancel}
    btnSize="sm"
  >
    Are you Sure ?
  </SweetAlert>
);

const QueryResultCCCComponent = () => {
  let history = useHistory();
  let location = useLocation();
  console.log("location from the result", location);
  //const resultData = location?.state?.state;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [queryResultData, setQueryResultData] = useState();
  const [tableData, setTableData] = useState(0);
  //const [exportData, setExportData] = useState(location && location.state && location.state.state && location.state.state.export)
  //const [stuPag, setStuPag] = useState(location.state.state.stud_arr);
  //const [dataForAllSelected, setDataForAllSelected] = useState(exportData.shift())
  const [clickedStudent, setClickedStudent] = useState();
  const [openSaveModal, setOpenSaveModal] = useState(false);
  //const [saveQueryData, setSaveQuerydata] = useState(resultData ? resultData.shift() : '')
  const [duplicateQueryData, setDuplicateQueryData] = useState(
    location && location.state ? location.state.duplicateQueryData : ""
  );
  //const [payloadForSaveResult, setPayloadForSaveResult] = useState(location && location.state && location.state.queryResultData ? location.state.queryResultData : '')
  const [from, setFrom] = useState(location?.state?.fromSavedQueriedResult);
  const [paginationCount, setPaginationCount] = useState();
  const [inputValue, setInputValue] = useState("");
  const [selectedRowFromTable, setSelectedRowFromTable] = useState();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedRowFromResultTable, setSelectedRowFromResultTable] = useState(
    []
  );
  const [saveSelection, setSaveSelection] = useState([]);
  const prevSelectionModel = React.useRef(selectedRowFromResultTable);
  const prevSelectionModelData = React.useRef(saveSelection);
  const [loaderForExport, setLoaderForExport] = useState(false);
  const [showGroupMessageAlert, setShowGroupMessageAlert] = useState(false);
  const [filteredSaveSelection, setFilteredSaveSelection] = useState([]);

  const [hideSaveResultButton, setHideSaveResultButton] = useState(
    location?.state?.from
  );

  const selectedAllData = location?.state?.state?.export.filter(
    (item) => item.hasOwnProperty("id") === true
  );

  // console.log(saveQueryData, from)
  const navigateToQuery = () => {
    localStorage.removeItem("query");
    history.push("/recruiter/college-query");
  };

  // useEffect(() => {
  //     if (openSaveModal === false) {
  //         location.state.openSaveModal = false
  //     }
  // }, [openSaveModal])

  const getPageData = () => {
    setLoading(true);
    let queryData = JSON.parse(localStorage.getItem("query"));
    if (queryData) {
      queryData.page = page;
    }

    QueryService.getCollegeResultPage(queryData)
      .then(async (data) => {
        setQueryResultData(data.data);
        console.log(data.data.student_list);
        const newArrayOfObj = data.data.student_list.map(
          ({ 11: id, ...rest }) => ({
            id,
            ...rest,
          })
        );
        //console.log(newArrayOfObj);
        setTableData(newArrayOfObj);
        setPaginationCount(data.data.total_pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  // useEffect(()=>{
  //     console.log("getting called from the useEffect", exportData)
  // },[exportData])

  const convertArrayToTable = async (apiArray, fileName) => {
    const tableRows = apiArray
      .map((obj) => [
        `<tr>
              ${Object.keys(obj)
                .map(
                  (key) =>
                    `<td>${
                      obj[key] === null || key === "id" || obj[key] === ""
                        ? ""
                        : obj[key]
                    }</td>`
                )
                .join("")}
           <tr/>`,
      ])
      .join("");
    const table = `<table>${tableRows}</table>`.trim();
    const xmlTable = createXMLTable(table, fileName);
    const downloadURL = createFileUrl(xmlTable);
    downloadFile(downloadURL, fileName);
  };

  const createXMLTable = (table, fileName) => {
    const xmlTable = `
              <html xmlns:o="urn:schemas-microsoft-com:office:office xmlns:x="urn:schemas-microsoft-com:office:excel"
             xmlns="http://www.w3.org/TR/REC-html40"
              >
                 <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
                 <head>
                    <xml>
                      <x:ExcelWorkbook>
                          <x:ExcelWorksheets>
                              <x:ExcelWorksheet>
                                  <x:Name>${fileName}</x:Name>
                                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                              </x:ExcelWorksheet>
                          </x:ExcelWorksheets>
                      </x:ExcelWorkbook>
                    </xml>
                 </head>
                 <body>
                   ${table}
                 </body>
              </html> `;
    return xmlTable;
  };

  const createFileUrl = (xmlTable) => {
    const tableBlob = new Blob([xmlTable], {
      type: "application/vnd.ms-excel;base64,",
    });
    const downloadURL = URL.createObjectURL(tableBlob);
    return downloadURL;
  };

  // useEffect(()=>{
  //     downloadFile(csvfile, "Test Download")
  // },[])

  const downloadFile = (downloadURL, fileName) => {
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
  };

  const exportResult = () => {
    setLoaderForExport(true);
    let query = JSON.parse(localStorage.getItem("query"));
    QueryService.exportResultCCC(query)
      .then((data) => {
        console.log("data from the service", data);
        downloadFile(data.data.download, "My File");
        setLoaderForExport(false);
      })
      .catch((error) => {
        setLoaderForExport(false);
        console.log("error from the service", error);
      });
  };
  useEffect(() => {
    if (!from) {
      getPageData();
    }
  }, [page]);

  useEffect(() => {
    if (from) {
      setTableData(selectedAllData);
    }
  }, []);

  const onCounselorContact = async (cell) => {
    history.push({
      pathname: "/recruiter/counselors",
      state: cell.row,
    });
  };

  const onStudentContact = async (cell) => {
    await setClickedStudent(cell.row);
    history.push( {
      pathname: "/recruiter/students",
      state: cell.row,
      openMessageModal: true,
      fromQueryResult: true,
    });
  };

  console.log(filteredSaveSelection);
  const onGroupMessage = () => {
    history.push( {
    pathname: '/recruiter/students',
    state: filteredSaveSelection,
    openMessageModal: true,
    fromQueryResult: true,
    payloadForSaveResult:queryResultData?.total_students,
    page:page,
})
}
  const columns = [
    {
      field: "clickToContact",
      headerName: "Click to Contacts",
      headerClassName: "super-app-theme--header",
      minWidth: "200",
      renderCell: (cell) => {
        return (
          <div className="contact-button-container">
            <button
              onClick={() => onCounselorContact(cell)}
              className="contact-button"
            >
              <PersonIcon />
              Counselor
            </button>
            <button
              onClick={() => onStudentContact(cell)}
              className="contact-button"
            >
              <PersonIcon />
              Student
            </button>
          </div>
        );
      },
    },
    {
      field: "0",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      type: "string",
      //editable: true,
      minWidth: "300",
      resizable: true,
      //  flex:1
    },
    {
      field: "1",
      headerName: "First name",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      //minWidth: "150",
      resizable: true,
      //  flex:1
    },
    {
      field: "2",
      headerName: "Last name",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      // flex:1
      //minWidth:150
    },
    {
      field: "3",
      headerName: "Ethnicity",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      // flex:1
      //minWidth:150
    },
    {
      field: "4",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 135,
      //flex:1
    },
    {
      field: "5",
      headerName: "Overall GPA",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 150,
      //flex:1
    },
    {
      field: "6",
      headerName: "Activities",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "7",
      headerName: "Athletics",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "8",
      headerName: "Intrests",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "9",
      headerName: "Academics",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "10",
      headerName: "School name",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      minWidth: 400,
      flex: 1,
    },
  ];

  const saveResult = () => {
    console.log("clicked");
    setOpenSaveModal(true);
  };

  const cancel = () => {
    history.push("/recruiter/college-query");
  };

  const handleCellClick = (cell) => {
    let cel = cell.row;
    if (saveSelection && Array.isArray(saveSelection)) {
      console.log("inside if");
      let filteredValue = saveSelection.includes(cel);
      console.log("save selection", saveSelection, filteredValue);
      if (filteredValue === true) {
        let index = saveSelection.indexOf(cel);
        saveSelection.splice(index, 1);
        setSaveSelection(saveSelection);
        return;
      }
    } else if (
      saveSelection === "selected All" &&
      typeof saveSelection === "string"
    ) {
      const selectedRows = tableData.filter((item) =>
        selectedRowFromResultTable.includes(item.id)
      );
      let filteredValue = selectedRows.includes(cel);
      if (filteredValue === true) {
        let index = selectedRows.indexOf(cel);
        selectedRows.splice(index, 1);
        setSaveSelection(selectedRows);
        return;
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(saveSelection)) {
      const ids = saveSelection.map((o) => o.id);
      let filtered = saveSelection.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      setFilteredSaveSelection(filtered);
    } else if (typeof saveSelection === "string") {
      setFilteredSaveSelection("selected All");
    }
  }, [saveSelection]);

  console.log("saveSelection", saveSelection);
  const setPreviousSelection = () => {
    let prev = prevSelectionModel.current;
    setTimeout(() => {
      setSelectedRowFromResultTable(prev);
    });
  };
  const setPreviousSelectionData = () => {
    let prev = prevSelectionModelData.current;
    setTimeout(() => {
      setSaveSelection(prev);
    });
  };
  useEffect(() => {
    setPreviousSelection();
    setPreviousSelectionData();
  }, [page, tableData]);

  const onSelection = (ids) => {
    console.log("getting clled");
    if (Array.isArray(ids) && ids.length > 0) {
      setSelectedRowFromResultTable(ids);
      const selectedIDs = new Set(ids);
      let selectedRows = tableData.filter((item) => selectedIDs.has(item.id));
      // console.log('ids from the onSelection', ids, selectedRows, selectedRows.length)
      // let filteredValue = saveSelection.filter(item => selectedIDs.has(!item.id))
      // selectedRows = filteredValue
      if (page !== 1) {
        setSelectAll(true);
        const selectedIDs = new Set(ids);
        setSaveSelection([...saveSelection, ...selectedRows]);
        // const selectedRows = tableData.filter(item => selectedIDs.has(item.id));
      } else if (page === 1 && selectedRows?.length === tableData.length) {
        setSelectAll(true);
        if (from) {
          setSaveSelection(selectedRows);
        } else if (!from) {
          setShowGroupMessageAlert(true);
          setSaveSelection("selected All");
        }
      } else if (page === 1 && selectedRows?.length !== tableData.length) {
        setSelectAll(true);
        const selectedIDs = new Set(ids);
        setSaveSelection([...saveSelection, ...selectedRows]);
        // const selectedRows = tableData.filter(item => selectedIDs.has(item.id));
      }
    } else if (Array.isArray(ids) && ids.length === 0) {
      setSaveSelection([]);
      setSelectedRowFromResultTable([]);
      setFilteredSaveSelection([]);
    }
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
      <div className="query-result-container">
        <div className="inner-query-result-container">
          <div className="view-result flex container">
            <div className="flex">
              <p style={{ fontSize: "18px" }} className="bold no-margin-bottom">
                VIEW QUERY RESULTS
              </p>
              <p
                onClick={navigateToQuery}
                className="no-margin-bottom font cursor"
                style={{ textDecoration: "underline", paddingLeft: "18px" }}
              >
                Create New Query Criteria
              </p>
            </div>
            <div>
              <p
                style={{ color: "#333333", opacity: "0.8" }}
                className="no-margin-bottom bold"
              >{`${
                queryResultData
                  ? queryResultData?.total_students
                  : from
                  ? tableData.length
                  : 0
              } Students Found`}</p>
            </div>
          </div>
          <div style={{ padding: "15px" }}>
            <p
              style={{ color: "#333333", opacity: "0.8" }}
              className="bold"
            >{`You are viewing ${
              tableData ? tableData?.length : 0
            } Student out of ${
              queryResultData ? queryResultData?.total_students : 0
            } Results`}</p>
          </div>
          {loading ? (
            <CustomLoader />
          ) : (
            <div
              className={
                paginationCount && paginationCount > 1
                  ? "table-container"
                  : "table-container-less"
              }
            >
              <DataGrid
                className="result-table"
                pageSize={pageSize}
                rows={tableData}
                columns={columns}
                rowsPerPageOptions={[20]}
                checkboxSelection
                onSelectionModelChange={(ids) => onSelection(ids)}
                hideFooterPagination={!from ? true : false}
                hideFooterSelectedRowCount={false}
                rowHeight={135}
                columnHeader
                selectionModel={selectedRowFromResultTable}
                onRowClick={(row) => handleCellClick(row)}
                disableSelectionOnClick={true}
                autoPageSize={true}
              />
            </div>
          )}

          <div className="result-button-container">
            {/* {!from && (
              <button
                onClick={() => saveResult()}
                className="modal-button-active"
              >
                Save Result
              </button>
            )} */}
            <button
              onClick={() =>
                saveSelection.length > 0 ? onGroupMessage() : ""
              }
              className="modal-button-inactive"
            >
              Group Message
            </button>
            <button
              onClick={
                from
                  ? () => convertArrayToTable(selectedAllData, "My File")
                  : () => exportResult()
              }
              style={{ justifyContent: "center" }}
              className="modal-button-inactive flex"
            >
              Exports Results
              {loaderForExport && (
                <span>
                  <Loader
                    type="TailSpin"
                    color="#E36221"
                    height={20}
                    width={20}
                  />
                </span>
              )}
            </button>
            <button onClick={() => cancel()} className="modal-button-inactive">
              Cancel
            </button>
          </div>
          {paginationCount && (
            <div>
              <Pagination
                onChange={(event, value) => {
                  prevSelectionModel.current = selectedRowFromResultTable;
                  prevSelectionModelData.current = saveSelection;
                  setPage(value);
                }}
                count={
                  from ? Math.ceil(tableData.length / 20) : paginationCount
                }
                color="primary"
                className="query-result-pagination"
              />
            </div>
          )}
        </div>
      </div>
      {showGroupMessageAlert && (
        <ShowAlert
          number={queryResultData?.total_students}
          onConfirm={onGroupMessage}
          onCancel={() => {
            setShowGroupMessageAlert(false);
            setSaveSelection([]);
            setSelectedRowFromResultTable([]);
          }}
        />
      )}
      {openSaveModal && (
        <SaveModal
          openSaveModal={openSaveModal}
          setOpenSaveModal={setOpenSaveModal}
          //duplicateQueryData={duplicateQueryData}
          //saveQueryData={selectedAllData}
          inputValueForSave={inputValue}
          setInputValueForSave={setInputValue}
          openErrorModal={true}
          setOpenErrorModal={setOpenErrorModal}
          //saveFromSavedResult={from ? true : false}
          // payloadForSaveResult={payloadForSaveResult}
        />
      )}
      {/* {
                openErrorModal && <ErrorModal
                    openErrorModal={openErrorModal}
                    setOpenErrorModal={setOpenErrorModal}
                    text="Query"
                />
            } */}
   </div>
      </div>
    </Fragment>
  );
};
export default QueryResultCCCComponent;
