import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { CommonButton } from "../../components";
import "../CreateStudentAccount/CreateStudentAccount.scss";
import { useHistory, Link } from "react-router-dom";
import GoogleLogo from "../../assets/utils/images/googleLogo.png";
import swal from "sweetalert";
import RegisterService from "../../services/RegisterService";
import Loading from "../../components/Loading/Loading";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import Storage from "../../services/Storage";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { userInfoAction } from "../../reducers/UserReducer";
import { useDispatch } from "react-redux";

const CreateStudentPersonal = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  let dispatch = useDispatch();
  const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!/\S+@\S+\.\S+/.test(email)) {
      swal({
        text: "Please enter valid email id",
        icon: "warning",
        buttons: {
          confirm: true,
        },
        closeOnClickOutside: false,
      });
    } else {
      let body = {
        type: "reg",
        email: email,
      };
      RegisterService.personalEmailCheck(body)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            history.push({
              pathname: "/create-student-otp",
              state: { email: email },
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          swal({
            text: error.response.data.error,
            icon: "warning",
            buttons: {
              confirm: "OK",
            },
          });
        });
    }
  };

  const firebaseConfig = {
    apiKey: "AIzaSyDbcu0BWliNcd4tkB7HBIX8gtJt5HXt3_0",
    authDomain: "siembra-341ab.firebaseapp.com",
    projectId: "siembra-341ab",
    storageBucket: "siembra-341ab.appspot.com",
    messagingSenderId: "937213911637",
    appId: "1:937213911637:web:7e03f3c1613c0a207e2adc",
    measurementId: "G-QPLCMMYN1L",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);

  // const googleSignIn = () => {
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(auth, provider);

  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     console.log("user", currentUser);
  //     const displayName = currentUser?.displayName;
  //     const [firstName, lastName] = displayName.split(" ");
  //     let body = {
  //       idToken: currentUser?.accessToken,
  //       email: currentUser?.email,
  //       first_name: firstName,
  //       last_name: lastName,
  //       profile: currentUser?.photoURL,
  //     };
  //     console.log("body", body);
  //     if (currentUser !== undefined) {
  //       fetch(`${API_URL}/api/ua/signup_google/`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //         body: JSON.stringify(body),
  //       })
  //         .then((response) => {
  //           console.log("inside resp");
  //           if (!response.ok) {
  //             throw new Error("Network response was not ok.");
  //           }
  //           return response.json();
  //         })
  //         .then((data) => {
  //           console.log("op0", data);
  //           Storage.setTokenInCookie(data.token);
  //           Storage.setSessionId(data.sessionid);
  //           if(data.role === 'student' && data.is_sch_email_verified === true){
  //             dispatch(userInfoAction(data))
  //             history.push("/student/goal/cc-requirement");
  //           }
  //           else{
  //             dispatch(userInfoAction(data))
  //             history.push("/student/dashboard");
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("error from the error", error);
  //           swal({
  //             text: "Something went wrong. Try again!",
  //             icon: "error",
  //             buttons: {
  //               OK: true,
  //             },
  //             closeOnClickOutside: false,
  //           })
  //         });
  //     }
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    const currentUser = auth.currentUser;
    if (currentUser) {
      handleSignIn(currentUser);
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          handleSignIn(result.user);
        })
        .catch((error) => {
          console.error("Google Sign-In Error:", error);
          swal({
            text: error,
            icon: "error",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          });
        });
    }
  };

  const handleSignIn = (currentUser) => {
    const displayName = currentUser?.displayName;
    const [firstName, lastName] = displayName.split(" ");
    let body = {
      idToken: currentUser?.accessToken,
      email: currentUser?.email,
      first_name: firstName,
      last_name: lastName,
      profile: currentUser?.photoURL,
    };

    fetch(`${API_URL}/api/ua/signup_google/`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        Storage.setTokenInCookie(data.token);
        Storage.setSessionId(data.sessionid);
        if (data.role === "student" && data.is_sch_email_verified === true && data?.student_status === 'regular') {
          dispatch(userInfoAction(data));
          history.push("/student/goal/cc-requirement");
        } else if (data.role === "student" && data.is_sch_email_verified === false && data?.student_status === 'regular') {
          dispatch(userInfoAction(data));
          history.push("/student/dashboard");
        } else {
          dispatch(userInfoAction(data));
          history.push("/student/brown-dashboard");
        }
      })
      .catch((error) => {
        console.log("error from the error", error);
        swal({
          text: "User already registered.",
          icon: "error",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        });
      });
  };

  return (
    <>
      {loading && <Loading />}
      <div className="studentAccountWrap">
        <div className="studentAccountInner text-center">
          <img
            src={IMG_URL + "white-logo.png"}
            alt="whiteLogo"
            className="logoImg img-fluid"
          />
          <div className="studentAccountBlockwrap">
            <div className="studentAccountBlockInner">
              <div className="boxWrap">
                <div className="boxwrapInner">
                  <h2 className="accountTitle text-center">
                    Create your student connect account
                  </h2>
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup className="text-left">
                      <Label className="customLabel">Email Id</Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your email id"
                        type="email"
                        name="userEmail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      />
                    </FormGroup>
                    <CommonButton
                      name="Continue"
                      type="submit"
                      btnClass="primaryBtn resendBtn"
                      btnWidth="320px"
                      btnHeight="54px"
                    />
                  </Form>
                  <div className="rememberText">
                    Already have an account?{" "}
                    <Link to="login" style={{ color: "#049804" }}>
                      Sign In
                    </Link>
                  </div>
                  <br />
                  <div className="orContainer">
                    <div className="line" />
                    <p>OR</p>
                    <div className="line" />
                  </div>
                  <br />
                  <div className="buttonShape">
                    <button className="googleButton" onClick={googleSignIn}>
                      <img
                        src={GoogleLogo}
                        alt="Google Logo"
                        className="googleLogo"
                      />
                      <span>Continue with Google</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudentPersonal;
