import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import AppHeader from '../../../Layout/AppHeader';
import AppSidebar from '../../../Layout/AppSidebar';
import StudentGoalService from '../../../services/StudentGoalService';
import Advert from '../../../components/Advert/Advert';
import Loading from '../../../components/Loading/Loading';
import './ParentGoal.scss';
import GoalNumberCard from '../../../components/parent/GoalNumberCard/GoalNumberCard';
import { urlConstants } from '../../../utils/urlConstants';
import { useDispatch, useSelector } from 'react-redux';
import { Roles } from '../../../utils/Roles';
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;



const goalList = [
    { value: 45, label: 'UC - Minimum Academic Requirements' },
    { value: 68, label: 'CSU - Minimum Academic Requirements' },
    { value: 65, label: 'CC - Community College Basic Admissions' },
]

const ParentGoal = () => {
    let dispatch = useDispatch();
    let userData = useSelector(state => state.UserReducer.userObj);
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [goalId, setGoalId] = useState('');
    const [goalData, setGoalData] = useState({});
    const [cardSelect, setcardSelect] = useState({ allStudent: true, onTrack: false, atRisk: false, offTrack: false, atBrown: false });
    const [filterStudentlist, setFilterStudentList] = useState([]);

    useEffect(() => {
        if (location?.pathname === '/parent/goal/cc-requirement') {
            setGoalId(65);
            getStudentByGoal(65);
        } else if (location?.pathname === '/parent/goal/csu-requirement') {
            setGoalId(68);
            getStudentByGoal(68);
        } else if (location?.pathname === '/parent/goal/uc-requirement') {
            setGoalId(45);
            getStudentByGoal(45);
        }

    }, []);

    const handleChangeGoal = (e) => {
        let { value } = e.target;
        setGoalId(value);
        if (Number(value) === 65) {
            history.push('/parent/goal/cc-requirement');
        } else if (Number(value) === 68) {
            history.push('/parent/goal/csu-requirement');
        } else if (Number(value) === 45) {
            history.push('/parent/goal/uc-requirement');
        }
    }

    const getStudentByGoal = (goalVal) => {
        let payload = {
            goal_id: goalVal
        }
        setLoading(true);
        StudentGoalService.getParentRoleStudentListbyGoal(payload)
            .then((res) => {
                if (res?.status === 200 && res?.data) {
                    console.log("datas",res?.data);
                    setGoalData(res?.data);
                    if (res?.data?.list && res?.data?.list.length > 0) {
                        getParentHistoryData(res?.data?.list[0]);
                    }
                    setFilterStudentList(res?.data?.list);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("error from the service", error);
                setLoading(false);
            });
    }

    const getParentHistoryData = (studentDetail) => {
        let payload = {
            student_id: studentDetail?.id || null,
            parent_id: null,
            role: Roles.PARENT_NAME,
            school_id: studentDetail?.school_id || null,
            device_type: 'web',
            mobile_os: null,
            app_version: null
        }
        setLoading(true);
        StudentGoalService.getParentHistoryData(payload)
            .then((res) => {
                // if (res?.status === 200) {}
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log("error from the service", error);
            });
    }

    const handleGoalCardClick = (key) => {
        let goalArr = goalData?.list || [];
        if (key === 'allStudent') {
            setcardSelect({ allStudent: true, onTrack: false, atRisk: false, offTrack: false, atBrown: false});
            setFilterStudentList(goalArr);
        } else if (key === 'onTrack') {  //green
            let filterOnTrack = goalArr && goalArr.length > 0 ? goalArr.filter(item => item.status === 'green') : [];
            setFilterStudentList(filterOnTrack);
            setcardSelect({ allStudent: false, onTrack: true, atRisk: false, offTrack: false, atBrown: false});
        } else if (key === 'atRisk') {  // yellow
            let filterOnTrack = goalArr && goalArr.length > 0 ? goalArr.filter(item => item.status === 'yellow') : [];
            setFilterStudentList(filterOnTrack);
            setcardSelect({ allStudent: false, onTrack: false, atRisk: true, offTrack: false, atBrown: false});
        } else if (key === 'offTrack') {  // red
            let filterOnTrack = goalArr && goalArr.length > 0 ? goalArr.filter(item => item.status === 'red') : [];
            setFilterStudentList(filterOnTrack);
            setcardSelect({ allStudent: false, onTrack: false, atRisk: false, offTrack: true, atBrown: false});
        } else if (key === 'atBrown') {  // brown
            let filterOnTrack = goalArr && goalArr.length > 0 ? goalArr.filter(item => item.status === 'brown') : [];
            setFilterStudentList(filterOnTrack);
            setcardSelect({ allStudent: false, onTrack: false, atRisk: false, offTrack: false, atBrown: true});
        }
    }

    const getItemStatus = (statusVal) => {
        if (statusVal === 'green') {
            return 'On Track';
        } else if (statusVal === 'yellow') {
            return 'At Risk';
        } else if (statusVal === 'red') {
            return 'Off Track'
        } else if (statusVal === 'brown') {
            return 'Transcript Missing';
        }
    }
    const handleStudentClick = (item) => {
        localStorage.setItem("studentData",JSON.stringify(item))
        history.push(`/parent/goal/student-course-detail?goalId=${goalId || ''}`);
    }

    const getEmtptyArrMsg = () => {
        if (cardSelect?.onTrack === true) {
            return 'You do not have any students On Track currently.'
        } else if (cardSelect?.atRisk === true) {
            return 'You do not have any students At Risk currently.'
        } else if (cardSelect?.offTrack === true) {
            return 'You do not have any students Off Track currently.'
        } else if (cardSelect?.atBrown === true) {
            return 'You do not have any students At Brown currently.'
        }
    }

    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <div className="parentGoal">
                            {loading && <Loading />}
                            <div className='loginUser'>Hello, <span className='logUserName'>{userData?.first_name + " " + userData?.last_name}</span></div>
                            {goalList && goalList.length > 0 ?
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className='text-left goalSelect'>
                                            <Input
                                                className="form-control"
                                                type='select'
                                                name='goalId'
                                                value={goalId || ''}
                                                onChange={handleChangeGoal}
                                            >
                                                {goalList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.value}>
                                                            {item.label}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                : ''}
                            <div className='googleAddWrap'>
                                <Row>
                                    <Col lg="12" className="mx-auto">
                                        <Advert />
                                    </Col>
                                </Row>
                            </div>
                            <div className='goalSummaryWrap'>
                                <div className="mainTitle">GOAL PROGRESS SUMMARY</div>
                                <div className='goalCardWrap'>
                                    <Row>
                                        <Col lg={3} xs={6}>
                                            <GoalNumberCard
                                                cardColorClass='blue'
                                                cardNumberData={goalData?.total}
                                                cardName='All Student'
                                                onClick={() => handleGoalCardClick('allStudent')}
                                                isSelected={cardSelect?.allStudent}
                                            />
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <GoalNumberCard
                                                cardColorClass='green'
                                                cardNumberData={goalData?.green}
                                                cardName='On Track'
                                                onClick={() => handleGoalCardClick('onTrack')}
                                                isSelected={cardSelect?.onTrack}
                                            />
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <GoalNumberCard
                                                cardColorClass='yellow'
                                                cardNumberData={goalData?.yellow}
                                                cardName='At Risk'
                                                onClick={() => handleGoalCardClick('atRisk')}
                                                isSelected={cardSelect?.atRisk}
                                            />
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <GoalNumberCard
                                                cardColorClass='red'
                                                cardNumberData={goalData?.red}
                                                cardName='Off Track'
                                                onClick={() => handleGoalCardClick('offTrack')}
                                                isSelected={cardSelect?.offTrack}
                                            />
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <GoalNumberCard
                                                cardColorClass='brown'
                                                cardNumberData={goalData?.brown}
                                                cardName='Transcript Missing'
                                                onClick={() => handleGoalCardClick('atBrown')}
                                                isSelected={cardSelect?.atBrown}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className='studentProgressWrap'>
                                <div className="mainTitle">All Students Progress</div>
                                <div className='progressWrap'>
                                    <Row>
                                        {filterStudentlist && filterStudentlist.length > 0 ?
                                            filterStudentlist.map((item, index) => {
                                                return (
                                                    <Col lg={6} key={index}>
                                                        <div className={`progressBlockWrp ${item?.status}`} onClick={() => handleStudentClick(item)}>
                                                            <div className='progressBlock'>
                                                                <div className='leftBlock'>
                                                                <img src={item?.profile_image !== null ? `${item?.profile_image}` : IMG_URL+"placeholder.jpg"} alt='studentAvatarIcon' className='avatarIcon' />
                                                                    <div className='textBlock'>
                                                                        <div className='title'>{item?.first_name + " " + item?.last_name}</div>
                                                                        <div className='grade'>Grade: {item?.current_grade}</div>
                                                                        <div className='status'><span className={`circleIcon ${item?.status}`}>&nbsp;</span>{getItemStatus(item?.status)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='rightBlock'>
                                                                <img src={IMG_URL+"rightAngelIcon.png"} alt='rightAngelIcon' width={10} height={18} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                            : <Col lg={6}>
                                                <div>{getEmtptyArrMsg()}</div>
                                            </Col>}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParentGoal;